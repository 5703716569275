<template>
  <div class="col-auto mb-3 mb-lg-0 px-lg-0">
    <div class="gold-text text-center bold my-2 mt-lg-0">
      Choose Quantity
    </div>
    <NumberField v-model="value" id="quantity" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    NumberField: defineAsyncComponent(() => import('../components/NumberField.vue'))
  },
  name: 'Select Quantity',
  props: ['modelValue'],
  emits: ['update:modelValue', 'updateQuantity'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      async set (value) {
        await this.$emit('update:modelValue', value)
        await this.$emit('updateQuantity')
      }
    }
  }
}
</script>
